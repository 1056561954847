<template>
  <div>
    <template v-if="diff">
      <p v-if="isOutOfSync">
        {{ $t('pipelineOutOfSync') }}
      </p>

      <CyNotification
        v-if="isOutOfSync"
        class="mb-8"
        :title="$t('syncNotification.title')"
        :notification="{ message: $t('syncNotification.message'), type: 'info' }"/>

      <div class="titles">
        <span>{{ isOutOfSync ? $t('untranslated.pipeline') : $t('before') }}</span>
        <span>{{ isOutOfSync ? $t('stack') : $t('after') }}</span>
      </div>

      <v-expansion-panels
        v-for="(group, groupIndex) in diffTree"
        :key="`group-${groupIndex}`"
        v-model="panels[group.name]"
        multiple>
        <v-expansion-panel
          v-for="(category, categoryIndex) in group.content"
          :key="`category-${groupIndex}-${categoryIndex}`">
          <v-expansion-panel-header>
            <div class="header">
              {{ `${group.name}/${category.name}` }}
              <span class="success--text">+{{ category.additionCount }}</span>
              <span class="error--text"> -{{ category.deletionCount }}</span>
            </div>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <CyCodeDiffBlock
              v-for="(block, blockIndex) in category.diff"
              :key="`line-${blockIndex}`"
              :block="block"/>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </template>

    <div
      v-else
      class="empty-state">
      {{ $t('noChangesDetected') }}
    </div>
  </div>
</template>

<script>
import CyCodeDiffBlock from '@/components/code-diff-block'
import CyNotification from '@/components/notification'

export default {
  name: 'CyCodeDiff',
  components: {
    CyCodeDiffBlock,
    CyNotification,
  },
  props: {
    diff: {
      type: Object,
      default: null,
    },
    isOutOfSync: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    panels: {},
  }),
  computed: {
    diffTree () {
      const diffTree = _.chain(this.diff)
        .omitBy((group) => _.isEmpty(group))
        .map((group, name) => {
          return {
            name,
            content: group.map(this.getDiff),
          }
        })
        .value()

      return diffTree
    },
  },
  watch: {
    diffTree: {
      deep: true,
      immediate: true,
      handler: 'initializePanels',
    },
  },
  methods: {
    initializePanels () {
      this.panels = this.diffTree.reduce((acc, { name, content }) => {
        acc[name] = _.range(content.length)
        return acc
      }, {})
    },
    getDiff (diffGroup) {
      const { diff, name } = diffGroup

      const sideBysideDiff = _.flow(
        this.makeGroupedDiff,
        this.makeContextDiff,
        this.makeSideBySideDiff,
      )(diff)

      return {
        name,
        diff: sideBysideDiff.groups,
        additionCount: sideBysideDiff.additionCount,
        deletionCount: sideBysideDiff.deletionCount,
      }
    },
    // Group diff lines by type,
    // creating junk groups with unchanged lines
    makeGroupedDiff (unformattedDiff) {
      const diff = [...unformattedDiff]
      const groups = []
      let group = this.createGroup()

      // Remove the last empty line that Concourse appends
      if (_.last(diff).line === '') diff.pop()

      for (const [index, line] of diff.entries()) {
        const { delta_type: type } = line

        // End previous groups
        if (type === '=') {
          if (!_.isEmpty(group.content) && !group.isJunk) {
            groups.push(group)
            group = this.createGroup()
          }
        } else {
          if (!_.isEmpty(group.content) && group.isJunk) {
            groups.push(group)
            group = this.createGroup()
          }
          group.isJunk = false
        }

        // Insert line
        group.content.push(line)

        if (!_.isEmpty(group.content) && index === diff.length - 1) {
          groups.push(group)
          group = this.createGroup()
        }
      }

      return groups
    },
    // Contextualise the diff by adding a few lines of context
    // at the start and end of non junk groups,
    // stripping those lines from adjacent junk groups.
    makeContextDiff (groupedDiff) {
      const groups = [...groupedDiff]
      for (const [index, group] of groups.entries()) {
        if (group.isJunk) continue

        const prevGroup = groups[index - 1] || {}
        if (!_.isEmpty(prevGroup.content) && prevGroup.isJunk) {
          const count = Math.min(3, prevGroup.content.length)
          const contextLines = prevGroup.content.splice(prevGroup.content.length - count, count)
          group.content.unshift(...contextLines)
        }

        const nextGroup = groups[index + 1] || {}
        if (!_.isEmpty(nextGroup.content) && nextGroup.isJunk) {
          const count = Math.min(3, nextGroup.content.length)
          const contextLines = nextGroup.content.splice(0, count)
          group.content.push(...contextLines)
        }
      }

      return groups
    },
    createGroup () {
      return {
        isJunk: true,
        content: [],
      }
    },
    // Duplicates unchanged lines, insert spacers,
    // and add line numbers to each lines.
    makeSideBySideDiff (groupedDiff) {
      const groups = [...groupedDiff]
      let beforeLineCount = 1
      let afterLineCount = 1
      let additionCount = 0
      let deletionCount = 0

      for (const group of groups) {
        group.content = group.content.reduce((acc, line, index, source) => {
          const { delta_type: type, line: content } = line

          if (type === '+') {
            additionCount++
            // Insert spacer on the left
            acc.push({ type: 'spacer' })
            acc.push({ type, content, lineNumber: afterLineCount++ })
          } else if (type === '-') {
            // Insert spacer on the right
            deletionCount++
            acc.push({ type, content, lineNumber: beforeLineCount++ })
            acc.push({ type: 'spacer' })
          } else {
            // Duplicate unchanged line
            acc.push({ type, content, lineNumber: beforeLineCount++ })
            acc.push({ type, content, lineNumber: afterLineCount++ })
          }

          return acc
        }, [])
      }

      return { groups, additionCount, deletionCount }
    },
  },
  i18n: {
    messages: {
      en: {
        after: 'After',
        before: 'Before',
        noChangesDetected: 'No changes detected.',
        pipelineOutOfSync: 'The pipeline diverges from its original stack. This may happen when the pipeline has been edited manually or when its stack or configuration has been updated.',
        stack: '@:Stack',
        syncNotification: {
          title: 'Keeping your pipeline and stack synced is considered a best practice.',
          message: 'Ultimately, any relevant change made to a pipeline should be reported on its stack. Once a stack is updated, use the "Refresh pipeline action to synchronize your pipeline.',
        },
      },
      es: {
        after: 'Después',
        before: 'Antes',
        noChangesDetected: 'No se detectaron cambios.',
        pipelineOutOfSync: 'La tubería diverge de su pila original. Esto puede suceder cuando la canalización se ha editado manualmente o cuando se ha actualizado su pila o configuración.',
        stack: '@:Stack',
        syncNotification: {
          title: 'Mantener la canalización y la pila sincronizados se considera una buena práctica.',
          message: 'En última instancia, cualquier cambio relevante realizado en una canalización debe informarse en su pila. Una vez que se actualiza una pila, use la acción "Actualizar canalización para sincronizar su canalización.',
        },
      },
      fr: {
        after: 'Après',
        before: 'Avant',
        noChangesDetected: 'Aucun changement détecté.',
        pipelineOutOfSync: `Le pipeline s'écarte de sa pile d'origine. Cela peut se produire lorsque le pipeline a été modifié manuellement ou lorsque sa pile ou sa configuration a été mise à jour.`,
        stack: '@:Stack',
        syncNotification: {
          title: 'Garder votre pipeline et votre pile synchronisés est considéré comme une bonne pratique.',
          message: `En fin de compte, toute modification pertinente apportée à un pipeline doit être signalée sur sa pile. Une fois qu'une pile est mise à jour, utilisez l'action "Actualiser le pipeline" pour synchroniser votre pipeline.`,
        },
      },
    },
  },
}
</script>

<style lang="scss" scoped>
$spacer: 4px;

.titles {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 1rem;
  color: get-color("primary");
  font-size: map.get($font-sizes, "base");
  font-weight: $font-weight-bold;
}

.header {
  font-family: $font-family-code;
}

.empty-state {
  margin: 3rem 0;
  font-weight: $font-weight-bold;
  text-align: center;
}

::v-deep {
  .v-item-group + .v-item-group,
  .v-expansion-panel + .v-expansion-panel {
    margin-top: $spacer * 4;
  }

  .v-expansion-panel-header {
    min-height: 48px !important;
    padding: 12px 24px !important;
  }

  .v-expansion-panel-content__wrap {
    padding: 0;
  }

  .v-expansion-panel-content {
    // Improve performance: as animating the diff (which is quite HTML heavy) is slow
    transition: none !important;
  }
}
</style>
