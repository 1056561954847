<template>
  <div class="pipeline-container d-flex flex-column flex-grow-1">
    <div id="icon-store"/>

    <v-row
      class="flex-grow-0 text-no-wrap"
      align="start">
      <v-col
        v-show="!isModifying"
        v-has-rights-to="pauseOrUnpausePipelinePermissionAction"
        class="status btn-paused flex-grow-0">
        <label>
          {{ $t('forms.status') }}
        </label>

        <CyBtnToggle
          v-model="pipelineStatus"
          class="status__buttons mt-2"
          :items="$static.statusActions"
          @input="togglePipeline"/>
      </v-col>

      <v-col
        v-show="!isModifying && groups.available.length"
        class="btn-pipeline">
        <label>
          {{ $t('views') }}
        </label>

        <div class="btn-pipeline__container">
          <template v-if="showGroupsAsTabs">
            <CyBtnToggle
              v-model="groups.selected"
              class="btn-pipeline__buttons mt-2"
              :items="groups.available"
              :disabled="!$cycloid.permissions.canDisplay('GetPipeline', pipelineCanonical)"
              @input="updateView"/>
          </template>

          <v-select
            v-else
            v-model="groups.selected"
            class="btn-pipeline__select pt-0"
            :items="groups.available"
            :disabled="!$cycloid.permissions.canDisplay('GetPipeline', pipelineCanonical)"
            @change="updateView($event)"/>
        </div>
      </v-col>

      <v-spacer/>

      <v-col
        class="button-actions flex-grow-0">
        <CyTag
          v-if="loadingPipelineSyncCheck"
          v-has-rights-to="['UpdatePipeline', pipelineCanonical]"
          class="sync-check-btn sync-check-btn--comparing mt-5 mr-2 px-2 d-inline"
          small
          variant="primary">
          <v-progress-circular
            color="primary"
            indeterminate
            size="14"
            width="1"/>
          {{ $t('comparing') }}
        </CyTag>

        <CyTag
          v-if="canShowOutOfSync"
          v-has-rights-to="['UpdatePipeline', pipelineCanonical]"
          class="sync-check-btn sync-check-btn--out-of-sync mt-6 mr-2 d-inline"
          element-type="button"
          small
          variant="warning"
          @click="openSyncDiffModal">
          <v-icon
            small>
            sync_problem
          </v-icon>
          {{ $t('outOfSync') }}
        </CyTag>

        <!-- Action Buttons -->
        <CyViewsEditPipelineMenu
          v-if="!isModifying"
          v-model="isMenuOpen"
          :actions="$static.pipelineActions"
          :can-update-pipeline="canUpdatePipeline"
          :can-update-project="canUpdateProject"
          :loading="resetLoading || refreshLoading || saveLoading"
          :use-stack-forms="useStackForms"
          @edit-pipeline="editPipeline"
          @edit-stack-forms="editStackForms"/>
        <div
          v-if="isModifying"
          class="space-x-2 mt-4">
          <CyButton
            :disabled="saveLoading || loading"
            variant="secondary"
            icon="close"
            @click="cancel()">
            {{ $t('forms.btnCancel') }}
          </CyButton>
          <CyButton
            v-has-rights-to="['DiffPipeline', pipelineCanonical]"
            :loading="saveLoading"
            :disabled="!isReviewable"
            icon="playlist_add_check"
            @click="openDiffModal()">
            {{ $t('reviewChanges') }}
          </CyButton>
        </div>
      </v-col>
    </v-row>

    <CyNotification
      class="width-100"
      theme="error"
      :content="errors"/>

    <div
      id="pipeline"
      v-if="!isModifying">
      <div
        v-if="loading"
        class="loading-spinner">
        <v-progress-circular
          indeterminate
          color="secondary"/>
      </div>
      <svg :class="['pipeline-graph test', { 'blur': loading }]"/>
    </div>

    <template v-if="!isModifying">
      <div class="legend">
        <div
          v-for="{ label, icon } of $static.legendItems"
          :key="label"
          class="legend__item">
          <dt :class="label">
            <v-icon v-if="icon">
              {{ icon }}
            </v-icon>
          </dt>
          <dd class="primary--text">
            {{ _.upperFirst(label) }}
          </dd>
        </div>
        <div class="legend__item">
          <dt class="manual-trigger"/>
          <dd class="primary--text">
            Manual trigger
          </dd>
        </div>
        <div class="legend__item">
          <dt class="dotted-line"/>
          <dd class="primary--text">
            Dependency
          </dd>
        </div>
        <div class="legend__item">
          <dt class="solid-line"/>
          <dd class="primary--text">
            Dependency (trigger)
          </dd>
        </div>
      </div>
    </template>

    <div
      v-if="reset || refresh"
      class="d-flex flex-column fill-height">
      <CyNotification
        v-if="!hasErrors"
        theme="warning"
        :content="warningMessage"/>
      <div class="container-reset-editor d-flex flex-column flex-grow-1">
        <CyWizardUsecaseSelection
          v-if="hasMultipleUsecase && canUpdatePipeline"
          v-model="selectedUsecaseKey"
          :usecases="serviceConfig"
          :confirm-change="isConfigurationDirty"
          class="mt-2 flex-grow-0">
          <template #before>
            <h4>{{ $t('selectPipelineToResetWith') }}</h4>
          </template>
        </CyWizardUsecaseSelection>

        <CyWizardEnvConfig
          v-if="showWizardEnvConfig"
          v-model="userServiceConfig"
          v-has-rights-to="['GetPipelineConfig', pipelineCanonical]"
          :service-canonical="project.service_catalog.ref"
          :environment="pipeline.environment.canonical"
          :project-canonical="project.canonical"
          :has-pipeline-errors="hasErrors"
          :config="selectedUsecase"
          relative-height
          display-expanded
          @valid="validateEditor($event)"
          @dirty="setConfigurationDirty()"/>
      </div>
    </div>

    <v-row
      v-if="edit && yaml"
      class="d-flex">
      <v-col :cols="isSplitScreen ? 5 : 12">
        <CyCodeEditor
          v-model="yaml"
          v-has-rights-to="['GetPipelineConfig', pipelineCanonical]"
          :value="yaml"
          :action-btn-icon="isSplitScreen ? 'reorder': 'vertical_split'"
          :action-btn-tooltip="isSplitScreen ? $t(`turnSplitScreenOff`) : $t(`turnSplitScreenOn`)"
          name="input-pipeline"
          code-lang="yaml"
          :label="$t('yamlPipeline')"
          @action-btn-clicked="toggleSplitScreen"
          @input="setConfigurationDirty()"/>
      </v-col>
      <v-col
        id="pipeline"
        v-if="isSplitScreen"
        cols="7">
        <div
          v-if="loading"
          class="loading-spinner">
          <v-progress-circular
            indeterminate
            color="secondary"/>
        </div>
        <svg
          :class="['pipeline-graph test', {
            'blur': loading,
          }]"/>
      </v-col>
    </v-row>

    <CyModal
      v-if="showDiff"
      :dialog-class="pipelineDiff || syncDiff ? 'v-dialog--large' : ''"
      :small="!pipelineDiff && !syncDiff"
      :header-title="diffModalText.title"
      :loading="saveLoading"
      :action-btn-text="diffModalText.actionBtnText"
      :action-btn-func="onDiffConfirm"
      :action-btn-disabled="sync ? !syncDiff : !pipelineDiff"
      :cancel-btn-func="onDiffModalCancel"
      modal-type="update"
      large
      scrollable>
      <CyCodeDiff
        :diff="sync ? _.get(syncDiff, 'diffs') : pipelineDiff"
        :is-out-of-sync="sync"/>
    </CyModal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import CyCodeDiff from '@/components/code-diff'
import CyCodeEditor from '@/components/code-editor'
import CyWizardEnvConfig from '@/components/wizard/env-config'
import CyWizardUsecaseSelection from '@/components/wizard/usecase-selection'
import CyBtnToggle from '@/components/btn-toggle'
import CyViewsEditPipelineMenu from '@/components/views/edit-pipeline-menu'
import * as d3 from 'd3'
import * as yaml from 'js-yaml'
import { Graph, objectIsEmpty, GraphNode, addIcon } from '@/vendor/concourse-graph'
import 'brace/mode/yaml'
import { checksPass } from '@/utils/helpers'
import REGEX from '@/utils/config/regex'

export default {
  name: 'CyViewsPipeline',
  components: {
    CyCodeDiff,
    CyCodeEditor,
    CyWizardEnvConfig,
    CyWizardUsecaseSelection,
    CyBtnToggle,
    CyViewsEditPipelineMenu,
  },
  props: {
    pipelineCanonical: {
      type: String,
      default: '',
    },
    projectCanonical: {
      type: String,
      default: '',
    },
    refreshInterval: {
      type: Number,
      default: 15000,
    },
  },
  data: ({ $route }) => ({
    errors: null,
    reset: false,
    refresh: false,
    sync: false,
    validCodeEditor: true,
    edit: false,
    isSplitScreen: false,
    pipeline: {},
    jobs: {},
    resources: {},
    groups: {
      available: [],
      selected: $route.hash ? $route.hash.substring(1) : null,
    },
    resetLoading: false,
    refreshLoading: false,
    loading: false,
    loadingPipelineSyncCheck: false,
    isPipelinePaused: false,
    pauseLoading: false,
    saveLoading: false,
    yaml: '',
    refreshIntervalID: null,
    serviceConfig: {},
    userServiceConfig: {},
    showDiff: false,
    selectedUsecaseKey: null,
    isConfigurationDirty: false,
    isMenuOpen: false,
    pipelineStatus: null,
  }),
  computed: {
    ...mapState({
      project: (state) => state.organization.project.detail,
      pipelineVariables: (state) => state.organization.project.pipeline.variables,
      pipelineErrors: (state) => [
        ...state.organization.project.pipeline.errors.diff,
        ...state.organization.project.pipeline.errors.variables,
        ...state.organization.project.pipeline.errors.syncDiff,
      ],
      syncDiff: (state) => state.organization.project.pipeline.syncDiff,
    }),
    ...mapGetters('organization/project/configRepository', [
      'configRepository',
    ]),
    ...mapGetters('organization/project', [
      'envForms',
    ]),
    ...mapGetters('organization/project/pipeline', [
      'pipelineDiff',
    ]),
    $static () {
      return {
        legendItems: [
          { label: 'succeeded', icon: 'check_circle' },
          { label: 'errored', icon: 'warning' },
          { label: 'aborted', icon: 'cancel' },
          { label: 'paused', icon: 'pause_circle_filled' },
          { label: 'pinned', icon: 'push_pin' },
          { label: 'failed', icon: 'error' },
          { label: 'pending', icon: 'pending' },
          { label: 'running' },
        ],
        pipelineActions: [
          {
            key: 'edit-stackforms',
            icon: 'edit_note',
            title: this.$t('actionEditStackFormsTitle'),
            text: this.$t('actionEditStackFormsText'),
            action: this.editStackForms,
          },
          {
            key: 'refresh',
            icon: 'autorenew',
            title: this.$t('actionRefreshTitle'),
            text: this.$t('actionRefreshText'),
            action: this.refreshPipeline,
            isPipelineAction: true,
          },
          {
            key: 'reset',
            icon: 'settings_backup_restore',
            title: this.$t('actionResetTitle'),
            text: this.$t('actionResetText'),
            action: this.resetPipeline,
            isPipelineAction: true,
          },
        ],
        statusActions: [
          {
            text: this.$t('running'),
            icon: 'play_arrow',
            key: 'running',
            value: 'unpause',
            isLoading: this.pauseLoading && this.isPipelinePaused,
            isInlineLoader: true,
          },
          {
            text: this.$t('pipeline.paused'),
            icon: 'pause',
            key: 'paused',
            value: 'pause',
            isLoading: this.pauseLoading && !this.isPipelinePaused,
            isInlineLoader: true,
          },
        ],
      }
    },
    showGroupsAsTabs () {
      return _.inRange(this.groups.available.length, 1, 5)
    },
    useStackForms  () {
      return !_.isEmpty(this.envForms[this.pipeline.environment]) && !_.isEmpty(this.configRepository)
    },
    canUpdatePipeline () {
      return this.$cycloid.permissions.canDisplay('UpdatePipeline', this.pipelineCanonical)
    },
    canUpdateProject () {
      return this.$cycloid.permissions.canDisplay('UpdateProject', this.projectCanonical)
    },
    warningMessage () {
      return this.reset
        ? this.$t('warningResetPipeline')
        : this.$t('warningRefreshPipeline')
    },
    diffModalText () {
      let actionBtnText = this.$t('forms.btnSave')
      let title = this.$t('editPipeline')

      if (this.reset) {
        actionBtnText = this.$t('btnReset')
        title = this.$t('btnReset')
      }
      if (this.refresh) {
        actionBtnText = this.$t('actionRefreshTitle')
        title = this.$t('actionRefreshTitle')
      }
      if (this.sync) {
        actionBtnText = this.$t('actionRefreshTitle')
        title = this.$t('actionSyncTitle')
      }

      return { actionBtnText, title }
    },
    hasErrors () {
      return !_.isEmpty(this.errors)
    },
    hasMultipleUsecase () {
      return _.size(this.serviceConfig) > 1
    },
    canShowOutOfSync () {
      return /^out[-_]of[-_]sync$/.test(_.get(this.syncDiff, 'synced')) &&
        !this.loadingPipelineSyncCheck &&
        !this.refreshLoading &&
        !this.resetLoading &&
        !this.saveLoading
    },
    isReviewable () {
      const { validCodeEditor, isConfigurationDirty } = this
      return checksPass({
        isNotSaving: !this.saveLoading,
        isNotLoading: !this.loading,
        isEditModeOrHasUsecase: this.edit || !_.isEmpty(this.selectedUsecase),
        validCodeEditor,
        isConfigurationDirty,
      })
    },
    selectedUsecase () {
      let config = null
      if (!this.hasMultipleUsecase) config = _.first(_.values(this.serviceConfig))
      else if (this.selectedUsecaseKey) config = this.serviceConfig[this.selectedUsecaseKey]
      return config
    },
    pauseOrUnpausePipelinePermissionAction () {
      const { isPipelinePaused, pipelineCanonical } = this
      return [
        isPipelinePaused ? 'PausePipeline' : 'UnpausePipeline',
        pipelineCanonical,
      ]
    },
    isModifying () {
      return this.edit || this.reset || this.refresh
    },
    showWizardEnvConfig () {
      const { resetLoading, refreshLoading, selectedUsecase } = this
      return (!resetLoading || !refreshLoading) && !!selectedUsecase
    },
  },
  watch: {
    pipeline: {
      async handler (newVal, oldVal) {
        if (!newVal) return

        const canCallSyncPipeline = checksPass({
          hasPipelineChanged: JSON.stringify(newVal) !== JSON.stringify(oldVal),
          hasConfigRepository: !_.isEmpty(this.configRepository),
          isNonStartStop: !(newVal?.name?.startsWith('start-stop')),
          isNameDefined: newVal?.name,
          hasPipelineUpdatePermissions: this.$cycloid.permissions.canDisplay('UpdatePipeline', this.pipelineCanonical),
        })
        if (canCallSyncPipeline) {
          this.$toggle.loadingPipelineSyncCheck(true)
          await this.SYNCED_PIPELINE({ pipelineCanonical: newVal?.name })
          this.$toggle.loadingPipelineSyncCheck(false)
        }
      },
      deep: true,
      immediate: true,
    },
    pipelineCanonical () {
      if (this.edit) {
        this.edit = false
        this.editPipeline()
      } else this.refreshPipelineView()
    },
    yaml () {
      this.updateYAML()
    },
  },
  async created () {
    await this.$evaluateUserActions([
      'UpdatePipeline',
      'PausePipeline',
      'UnpausePipeline',
      'DiffPipeline',
      'GetPipelineConfig',
      'GetPipeline',
    ])
  },
  async mounted () {
    await this.refreshPipelineView()
  },
  beforeDestroy () {
    if (this.refreshIntervalID) window.clearInterval(this.refreshIntervalID)
  },
  methods: {
    ...mapActions('organization/project/pipeline', [
      'GET_PIPELINE_DIFF',
      'GET_PIPELINE_VARIABLES',
      'SYNCED_PIPELINE',
    ]),
    ...mapActions('alerts', [
      'SHOW_ALERT',
    ]),
    ...mapMutations('organization/project', [
      'CLEAR_PROJ_ERRORS',
    ]),
    ...mapMutations('organization/project/pipeline', [
      'SET_PIPELINE_SYNCED_DIFF',
    ]),
    validateEditor (event) {
      this.validCodeEditor = event
    },
    togglePipeline (action) {
      if (this.isPipelinePaused && action === 'pause') return
      if (!this.isPipelinePaused && action === 'unpause') return

      this.togglePipelineRequest(action)
    },
    async togglePipelineRequest (pipelineState) {
      this.pauseLoading = true
      const { errors } = await this.$cycloid.ydAPI[`${pipelineState}Pipeline`](this.orgCanonical, this.project.canonical, this.pipelineCanonical) || {}

      if (!errors) {
        this.SHOW_ALERT({ type: 'success', content: this.$t(`alerts.success.pipeline.toggle.${pipelineState}d`) })
      } else {
        this.SHOW_ALERT({ type: 'warning', content: this.$t('alerts.warning.pipeline.togglePause') })
      }

      this.$emit('refresh-pipelines-selector')
      await this.getConcourseInformation(true)
      this.pauseLoading = false
    },
    updateView (view) {
      window.location.hash = view
      this.groups.selected = view
      this.edit ? this.updateYAML() : this.updateSVG()
    },
    refreshPipelineView () {
      window.clearInterval(this.refreshIntervalID)
      this.getConcourseInformation(false)

      this.refreshIntervalID = window.setInterval(() => {
        this.getConcourseInformation(true)
      }, this.refreshInterval)
    },
    editStackForms () {
      this.$router.push({ name: 'environmentConfig', params: { envCanonical: this.pipeline.environment } })
    },
    updateYAML () {
      let obj
      try {
        obj = yaml.load(this.yaml)
        this.errors = null
        if (!obj) return
      } catch (error) {
        this.errors = [{ code: error.name, message: error.message }]
        return
      }

      const groups = obj.groups
      const resources = obj.resources
      const jobs = obj.jobs || []

      if (!_.isEmpty(groups)) {
        this.groups.available = []
        let isSelectedGroupExisting = false
        for (const groupIdx in groups) {
          if (this.groups.selected === groups[groupIdx].name) isSelectedGroupExisting = true
          this.groups.available.push({
            value: groups[groupIdx].name,
            text: groups[groupIdx].name,
            key: groups[groupIdx].name,
          })
        }
        if (!isSelectedGroupExisting) this.groups.selected = null
      }

      try {
        // Recurse each job
        for (let jobIndex = 0, jobLen = jobs.length; jobIndex < jobLen; jobIndex++) {
          const job = jobs[jobIndex]
          // Does the job have an aggregate plan
          const inputs = []
          const outputs = []

          for (let planIndex = 0, planLen = job.plan.length; planIndex < planLen; planIndex++) {
            const plan = job.plan[planIndex]

            job.finished_build = null

            job.groups = []
            for (const groupIdx in groups) {
              if (groups[groupIdx].jobs.includes(job.name)) {
                job.groups.push(groups[groupIdx].name)
              }
            }

            if (plan.get) {
              inputs.push({
                name: plan.get,
                resource: plan.get,
                trigger: plan.trigger !== undefined ? plan.trigger : false,
                passed: plan.passed !== undefined ? plan.passed : [],
              })
            }
            if (plan.put) {
              outputs.push({ name: plan.put, resource: plan.put })
            }

            if (plan.in_parallel) {
              for (const { get, put, trigger = false, passed = [] } of plan.in_parallel.steps) {
                if (get) inputs.push({ name: get, resource: get, trigger, passed })
                if (put) outputs.push({ name: put, resource: put })
              }
            }

            if (plan.do) {
              for (const { get, put, trigger = false, passed = [] } of plan.do) {
                if (get) inputs.push({ name: get, resource: get, trigger, passed })
                if (put) outputs.push({ name: put, resource: put })
              }
            }
          }
          job.inputs = inputs
          job.outputs = outputs
        }
        const svg = this.createPipelineSvg(d3.select('.pipeline-graph'))
        if (svg.node() !== null) {
          this.redrawFunction(svg, jobs, resources)
        }
      } catch (error) {
        console.error(`error: ${error.name} - ${error.message}`)
      }
    },
    updateSVG () {
      const svg = this.createPipelineSvg(d3.select('.pipeline-graph'))
      if (svg.node() !== null) {
        this.redrawFunction(svg, this.jobs, this.resources)
      }
    },
    redrawFunction (svg, jobs, resources) {
      const self = this
      let currentHighlight

      const graph = self.createGraph(svg, jobs, resources)

      svg.selectAll('g.edge').remove()
      svg.selectAll('g.node').remove()

      const svgEdges = svg.selectAll('g.edge')
        .data(graph.edges())

      svgEdges.exit().remove()

      const svgNodes = svg.selectAll('g.node')
        .data(graph.nodes())

      svgNodes.exit().remove()

      const svgEdge = svgEdges.enter().append('g')
        .attr('class', (edge) => {
          const classes = ['edge', edge.source.node.status]
          if (edge.customData) {
            if (edge.customData.hasOwnProperty('trigger') && !edge.customData.trigger) {
              classes.push('trigger-false')
            }
          }
          return classes.join(' ')
        })

      function highlight (thing) {
        if (!thing.key) return

        currentHighlight = thing.key

        svgEdges.each(function (edge) {
          if (edge.source.key === thing.key) {
            d3.select(this).classed('active', true)
          }
        })

        svgNodes.each(function (node) {
          if (node.key === thing.key) {
            d3.select(this).classed('active', true)
          }
        })
      }

      function lowlight (thing) {
        if (!thing.key) {
          return
        }

        currentHighlight = undefined
        if (svgEdges) svgEdges.classed('active', false)
        if (svgNodes) svgNodes.classed('active', false)
      }

      const svgNode = svgNodes.enter().append('g')
        .attr('class', function (node) { return 'node ' + node.class })
        .on('mouseover', highlight)
        .on('mouseout', lowlight)

      const nodeLink = svgNode.append('svg:a')
        .attr('xlink:href', function (node) { return node.url })
        .on('click', function (node) {
          const ev = d3.event
          if (ev.ctrlKey || ev.altKey || ev.metaKey || ev.shiftKey) {
            return
          }

          if (ev.button !== 0) {
            return
          }

          ev.preventDefault()
          if (node.url) {
            self.$router.push({ path: node.url })
          }
        })

      const jobStatusBackground = nodeLink.append('rect')
        .attr('height', function (node) { return node.height() })

      const animatableBackground = nodeLink.append('foreignObject')
        .attr('class', 'js-animation-wrapper')
        .attr('height', function (node) { return node.height() + (2 * node.animationRadius()) })
        .attr('x', function (node) { return -node.animationRadius() })
        .attr('y', function (node) { return -node.animationRadius() })

      const animationPadding = animatableBackground.append('xhtml:div')
        .style('padding', function (node) {
          return node.animationRadius() + 'px'
        })

      animationPadding.style('height', function (node) { return node.height() + 'px' })

      const animationTarget = animationPadding.append('xhtml:div')

      animationTarget.attr('class', 'animation')
      animationTarget.style('height', function (node) { return node.height() + 'px' })

      const pinIconWidth = 6
      const pinIconHeight = 9.75

      nodeLink.filter(function (node) { return node.pinned() }).append('image')
        .attr('xlink:href', '/static/images/pipelines/pin-ic-white.svg')
        .attr('width', pinIconWidth)
        .attr('y', function (node) { return node.height() / 2 - pinIconHeight / 2 })
        .attr('x', function (node) { return node.padding() })

      const iconSize = 12
      nodeLink.filter(function (node) { return node.has_icon() }).append('use')
        .attr('xlink:href', (node) => `#${node.id}-svg-icon`)
        .attr('width', iconSize)
        .attr('height', iconSize)
        .attr('fill', 'white')
        .attr('y', function (node) { return node.height() / 2 - iconSize / 2 })
        .attr('x', function (node) { return node.padding() + (node.pinned() ? pinIconWidth + node.padding() : 0) })

      nodeLink.append('text')
        .text(function (node) { return node.name })
        .attr('dominant-baseline', 'middle')
        .attr('text-anchor', function (node) { return node.pinned() || node.has_icon() ? 'end' : 'middle' })
        .attr('x', function (node) { return node.pinned() || node.has_icon() ? node.width() - node.padding() : node.width() / 2 })
        .attr('y', function (node) { return node.height() / 2 })

      jobStatusBackground.attr('width', function (node) { return node.width() })
      animatableBackground.attr('width', function (node) { return node.width() + (2 * node.animationRadius()) })
      animationTarget.style('width', function (node) { return node.width() + 'px' })
      animationPadding.style('width', function (node) { return node.width() + 'px' })

      graph.layout()

      const failureCenters = []
      const epsilon = 2
      const graphNodes = graph.nodes()
      for (const i in graphNodes) {
        if (graphNodes[i].status === 'failed') {
          const xCenter = graphNodes[i].position().x + (graphNodes[i].width() / 2)
          let found = false
          for (const i in failureCenters) {
            if (Math.abs(xCenter - failureCenters[i]) < epsilon) {
              found = true
              break
            }
          }
          if (!found) {
            failureCenters.push(xCenter)
          }
        }
      }

      svg.selectAll('g.fail-triangle-node').remove()
      const failTriangleBottom = 20
      const failTriangleHeight = 24
      for (const i in failureCenters) {
        const triangleNode = svg.append('g')
          .attr('class', 'fail-triangle-node')
        triangleNode.append('path')
          .attr('class', 'fail-triangle-outline')
          .attr('d', 'M191.62,136.3778H179.7521a5,5,0,0,1-4.3309-7.4986l5.9337-10.2851a5,5,0,0,1,8.6619,0l5.9337,10.2851A5,5,0,0,1,191.62,136.3778Z')
          .attr('transform', 'translate(-174.7446 -116.0927)')
        triangleNode.append('path')
          .attr('class', 'fail-triangle')
          .attr('d', 'M191.4538,133.0821H179.9179a2,2,0,0,1-1.7324-2.9994l5.7679-9.9978a2,2,0,0,1,3.4647,0l5.7679,9.9978A2,2,0,0,1,191.4538,133.0821Z')
          .attr('transform', 'translate(-174.7446 -116.0927)')
        const triangleBBox = triangleNode.node().getBBox()
        const triangleScale = failTriangleHeight / triangleBBox.height
        const triangleWidth = triangleBBox.width * triangleScale
        const triangleX = failureCenters[i] - (triangleWidth / 2)
        const triangleY = -failTriangleBottom - failTriangleHeight
        triangleNode.attr('transform', 'translate(' + triangleX + ', ' + triangleY + ') scale(' + triangleScale + ')')
      }

      nodeLink.attr('class', function (node) {
        const classes = []

        if (!node.url) {
          classes.push('inactive-link')
        }

        if (node.debugMarked) {
          classes.push('marked')
        }

        if (node.columnMarked) {
          classes.push('column-marked')
        }

        return classes.join(' ')
      })

      svgNode.attr('transform', function (node) {
        const position = node.position()
        return `translate(${position.x}, ${position.y})`
      })

      svgEdge.append('path')
        .attr('d', function (edge) { return edge.path() })
        .on('mouseover', highlight)
        .on('mouseout', lowlight)

      const bbox = svg.node().getBBox()
      d3.select(svg.node().parentNode)
        .attr('viewBox', '' + (bbox.x - 20) + ' ' + (bbox.y - 20) + ' ' + (bbox.width + 40) + ' ' + (bbox.height + 40))

      const $jobs = document.querySelectorAll('.job')
      const jobAnimations = []
      for (let jobIndex = 0, jobLen = $jobs.length; jobIndex < jobLen; jobIndex++) {
        jobAnimations.push($jobs[jobIndex].cloneNode(true))
      }

      const largestEdge = Math.max(bbox.width, bbox.height)

      for (let i = 0, len = jobAnimations.length; i < len; i++) {
        const $el = jobAnimations[i]
        const $link = $el.querySelector('a')
        const $foreignObject = $link.querySelector('foreignObject')
        $link.removeChild($foreignObject)
        $el.classList.remove('job')
        $el.classList.add('job-animation-node')
        $el.removeChild($link)
        $el.appendChild($foreignObject, $el.childNodes[1])
      }

      for (let jobIndex = 0, jobLen = $jobs.length; jobIndex < jobLen; jobIndex++) {
        const $jobLink = $jobs[jobIndex].querySelector('a')
        const $jobsAnimWrapper = $jobLink.querySelector('.js-animation-wrapper')
        $jobLink.removeChild($jobsAnimWrapper)
      }

      const svgpipeline = document.querySelector('#pipeline > svg > g')
      for (let jobIndex = 0, jobLen = jobAnimations.length; jobIndex < jobLen; jobIndex++) {
        svgpipeline.insertBefore(jobAnimations[jobIndex], svgpipeline.childNodes[0])
      }

      const animationAll = document.querySelectorAll('.animation')
      for (let animIndex = 0, animLen = animationAll.length; animIndex < animLen; animIndex++) {
        if (largestEdge < 500) {
          animationAll[animIndex].classList.add('animation-small')
        } else if (largestEdge < 1500) {
          animationAll[animIndex].classList.add('animation-medium')
        } else if (largestEdge < 3000) {
          animationAll[animIndex].classList.add('animation-large')
        } else {
          animationAll[animIndex].classList.add('animation-xlarge')
        }
      }

      if (currentHighlight) {
        svgNodes.each(function (node) {
          if (node.key === currentHighlight) {
            highlight(node)
          }
        })

        svgEdges.each(function (node) {
          if (node.key === currentHighlight) {
            highlight(node)
          }
        })
      }
    },
    groupsMatch (objGroups, groups) {
      if (objectIsEmpty(groups)) {
        return true
      }
      for (const i in objGroups) {
        if (groups === objGroups[i]) {
          return true
        }
      }
      return false
    },
    createGraph (svg, jobs, resources) {
      const graph = new Graph()

      const resourceURLs = {}
      const resourceBuild = {}
      const resourcePinned = {}
      const resourceIcons = {}

      const baseURL = `/organizations/${this.orgCanonical}/projects/${this.project.canonical}/environments/${this.pipeline.environment.canonical}/pipelines/${this.pipelineCanonical}`
      for (const i in resources) {
        const resource = resources[i]
        resourceURLs[resource.name] = `${baseURL}/resources/${resource.name}${this.groups.selected ? `#${this.groups?.selected}` : ''}`
        resourceBuild[resource.name] = resource.build
        resourcePinned[resource.name] = resource.pinned_version
        resourceIcons[resource.name] = resource.icon
      }

      for (const i in jobs) {
        const job = jobs[i]
        if (!this.groupsMatch(job.groups, this.groups.selected)) {
          continue
        }

        const id = `job-${job.name}`

        const classes = ['job']

        let url = null
        if (!this.edit) url = `${baseURL}/jobs/${job.name}${this.groups.selected ? `#${this.groups?.selected}` : ''}`
        if (job.next_build) {
          const build = job.next_build
          url = `${baseURL}/jobs/${build.job_name}/builds/${build.id}${this.groups.selected ? `#${this.groups?.selected}` : ''}`
        } else if (job.finished_build) {
          const build = job.finished_build
          url = `${baseURL}/jobs/${build.job_name}/builds/${build.id}${this.groups.selected ? `#${this.groups?.selected}` : ''}`
        }

        let status = ''
        if (job.paused) {
          status = 'paused'
        } else if (job.finished_build) {
          status = job.finished_build.status
        } else {
          status = 'no-builds'
        }

        classes.push(status)

        if (this.hasManualTrigger(job)) classes.push('manual-trigger')

        if (job.next_build) {
          classes.push(job.next_build.status)
        }

        graph.setNode(id, new GraphNode({
          id,
          name: job.name,
          class: classes.join(' '),
          status,
          url,
          svg,
        }))
      }

      const resourceStatus = function (resource) {
        let status = ''
        if (resourceBuild[resource]) {
          status += ' ' + resourceBuild[resource].status
        }
        if (resourcePinned[resource]) {
          status += ' pinned'
        }

        return status
      }

      // Populate job output nodes and edges
      for (const i in jobs) {
        const job = jobs[i]
        const id = `job-${job.name}`

        if (!this.groupsMatch(job.groups, this.groups.selected)) {
          continue
        }

        for (const j in job.outputs) {
          const output = job.outputs[j]
          const outputId = `job-${job.name}-output-${output.resource}`

          let jobOutputNode = graph.node(outputId)
          if (!jobOutputNode) {
            addIcon(resourceIcons[output.resource], outputId)
            jobOutputNode = new GraphNode({
              id: outputId,
              name: output.resource,
              icon: resourceIcons[output.resource],
              key: output.resource,
              class: 'resource output' + resourceStatus(output.resource),
              repeatable: true,
              url: resourceURLs[output.resource],
              svg,
            })

            graph.setNode(outputId, jobOutputNode)
          }
          graph.addEdge(id, outputId, output.resource, null)
        }
      }

      // Populate dependant job input edges
      // Do this first as this is what primarily determines node ranks
      for (const i in jobs) {
        const job = jobs[i]
        const id = `job-${job.name}`

        if (!this.groupsMatch(job.groups, this.groups.selected)) {
          continue
        }

        for (const j in job.inputs) {
          const input = job.inputs[j]

          if (_.get(input, 'passed', [].length)) {
            for (const p in input.passed) {
              const sourceJobNode = `job-${input.passed[p]}`

              const sourceOutputNode = `job-${input.passed[p]}-output-${input.resource}`
              const sourceInputNode = `job-${input.passed[p]}-input-${input.resource}`

              let sourceNode
              if (graph.node(sourceOutputNode)) {
                sourceNode = sourceOutputNode
              } else {
                if (!graph.node(sourceInputNode)) {
                  addIcon(resourceIcons[input.resource], sourceInputNode)
                  graph.setNode(sourceInputNode, new GraphNode({
                    id: sourceInputNode,
                    name: input.resource,
                    icon: resourceIcons[input.resource],
                    key: input.resource,
                    class: 'resource constrained-input' + resourceStatus(input.resource),
                    repeatable: true,
                    url: resourceURLs[input.resource],
                    svg,
                  }))
                }

                if (graph.node(sourceJobNode)) {
                  graph.addEdge(sourceJobNode, sourceInputNode, input.resource, null)
                }

                sourceNode = sourceInputNode
              }

              graph.addEdge(sourceNode, id, input.resource, { trigger: input.trigger })
            }
          }
        }
      }

      // Populate unconstrained job inputs
      // Now that we know the rank, draw one unconstrained input per rank
      for (const i in jobs) {
        const job = jobs[i]
        const id = `job-${job.name}`

        if (!this.groupsMatch(job.groups, this.groups.selected)) {
          continue
        }

        graph.node(id)

        for (const j in job.inputs) {
          const input = job.inputs[j]
          const status = ''

          if ((input.passed || []).length === 0) {
            const inputId = `job-${job.name}-input-${input.resource}-unconstrained`

            if (!graph.node(inputId)) {
              addIcon(resourceIcons[input.resource], inputId)
              graph.setNode(inputId, new GraphNode({
                id: inputId,
                name: input.resource,
                icon: resourceIcons[input.resource],
                key: input.resource,
                class: 'resource input' + resourceStatus(input.resource),
                status,
                repeatable: true,
                url: resourceURLs[input.resource],
                svg,
                equivalentBy: `${input.resource}-unconstrained`,
              }))
            }

            graph.addEdge(inputId, id, input.resource, { trigger: input.trigger })
          }
        }
      }

      graph.computeRanks()
      graph.collapseEquivalentNodes()
      graph.addSpacingNodes()

      return graph
    },
    hasManualTrigger ({ inputs = [] }) {
      if (_.isEmpty(inputs)) return true
      return !inputs.some(({ trigger }) => trigger)
    },
    createPipelineSvg (svg) {
      let g = d3.select('g.test')
      if (g.empty()) {
        svg.append('defs').append('filter')
          .attr('id', 'embiggen')
          .append('feMorphology')
          .attr('operator', 'dilate')
          .attr('radius', '4')

        g = svg.append('g').attr('class', 'test')

        svg.on('mousedown', function () {
          const ev = d3.event
          if (ev.button || ev.ctrlKey) {
            ev.stopImmediatePropagation()
          }
        }).call(d3.zoom().scaleExtent([0.5, 10]).on('zoom', function () {
          const ev = d3.event
          g.attr('transform', 'translate(' + ev.transform.x + ', ' + ev.transform.y + ') scale(' + ev.transform.k + ')')
        }))
      }
      return g
    },
    async getConcourseInformation (isRefreshing) {
      if (!isRefreshing) this.loading = true

      const requests = [
        this.$cycloid.ydAPI.getPipeline(this.orgCanonical, this.project.canonical, this.pipelineCanonical),
        this.$cycloid.ydAPI.getPipelineResources(this.orgCanonical, this.project.canonical, this.pipelineCanonical),
        this.$cycloid.ydAPI.getJobs(this.orgCanonical, this.project.canonical, this.pipelineCanonical),
      ]
      const [resBodyPipeline, resBodyResources, resBodyJobs] = await Promise.all(requests)

      if (resBodyPipeline && resBodyPipeline.data) {
        this.pipeline = resBodyPipeline.data
        this.isPipelinePaused = this.pipeline.paused
        this.pipelineStatus = this.isPipelinePaused ? 'pause' : 'unpause'
        this.selectedUsecaseKey = this.pipeline.use_case
      }
      if (resBodyResources && resBodyResources.data) {
        this.resources = resBodyResources.data
      }
      if (resBodyJobs && resBodyJobs.data) {
        this.jobs = resBodyJobs.data
      }

      this.groups.available = []
      for (const group in this.pipeline.groups) {
        this.groups.available.push({
          value: this.pipeline.groups[group].name,
          text: this.pipeline.groups[group].name,
          key: this.pipeline.groups[group].name,
        })
      }

      if (!isRefreshing) {
        // If the route has an existing group in the hashtag, preselect it,
        // otherwise selects the first available group
        if (this.$route.hash) {
          const hashValue = this.$route.hash.replace('#', '')
          const selectedGroupIdx = this.groups.available.findIndex((group) =>
            group.value === hashValue,
          )
          if (selectedGroupIdx > -1) {
            this.groups.selected = this.groups.available[selectedGroupIdx]?.value
          } else {
            this.groups.selected = this.groups.available[0]?.value
          }
        } else {
          this.groups.selected = this.groups.available[0]?.value
        }
      }

      this.updateSVG()

      if (!isRefreshing) this.loading = false
    },
    async getPipelineConfig (isRefreshing) {
      if (!isRefreshing) this.loading = true

      const { data } = await this.$cycloid.ydAPI.getPipelineConfig(this.orgCanonical, this.project.canonical, this.pipelineCanonical)
      if (!_.$isEmpty(data)) {
        this.yaml = yaml.dump(JSON.parse(data), { lineWidth: 1000 })
      }

      if (!isRefreshing) this.loading = false
    },
    getLocalPipelineConfig () {
      const passedConfig = (this.reset || this.refresh) ? this.userServiceConfig.pipeline.pipeline.content : this.yaml
      // Check that the pipeline isn't a template. If it is, we're passing it as a text, else, we load the yaml
      const yamlConfig = REGEX.STACK_TEMPLATE.test(passedConfig) ? passedConfig : yaml.load(passedConfig)
      const config = { passed_config: JSON.stringify(yamlConfig) }
      if (this.reset || this.refresh) config.yaml_vars = this.userServiceConfig.pipeline.variables.content

      return config
    },
    async getRemotePipelineConfig () {
      this.SET_PIPELINE_SYNCED_DIFF(null)
      await this.refreshPipeline({ suppressModal: true })
      const passedConfig = this.serviceConfig[this.pipeline.use_case].pipeline.pipeline.content
      const yamlConfig = REGEX.STACK_TEMPLATE.test(passedConfig) ? passedConfig : yaml.load(passedConfig)
      return {
        passed_config: JSON.stringify(yamlConfig),
        yaml_vars: this.serviceConfig[this.pipeline.use_case].pipeline.variables.content,
      }
    },
    async updatePipeline () {
      this.saveLoading = true
      this.errors = null

      try {
        const config = this.sync ? await this.getRemotePipelineConfig() : this.getLocalPipelineConfig()
        const { data, errors } = await this.$cycloid.ydAPI.updatePipeline(this.orgCanonical, this.project.canonical, this.pipelineCanonical, config) || {}
        // Toggle the edit pipeline if the new pipeline has been saved
        if (data) {
          this.cancel()
          this.SHOW_ALERT({ type: 'success', content: this.$t('alerts.success.pipeline.update') })
        }
        if (errors) this.errors = errors
      } catch (error) {
        if (error instanceof yaml.YAMLException) {
          this.SHOW_ALERT({ type: 'warning', content: this.$t('alerts.errors.pipeline.configuration.update', { errorMessage: error.message }) })
        }
      }

      this.saveLoading = false
      this.isMenuOpen = false
    },
    async getServiceCatalogConfig () {
      this.errors = null
      const { data, errors } = await this.$cycloid.ydAPI.getServiceCatalogConfig(this.project.service_catalog.ref, this.orgCanonical, this.selectedUsecaseKey, this.projectCanonical, this.pipeline.environment.canonical) || {}
      if (errors) this.errors = errors
      if (data) {
        // Strip out non pipeline related groups
        this.serviceConfig = _.mapValues(data, (usecase) => ({
          name: usecase.name,
          description: usecase.description,
          pipeline: usecase.pipeline,
        }))
        this.validCodeEditor = true
      }
    },
    async resetPipeline () {
      this.CLEAR_PROJ_ERRORS('pipelines')
      this.resetLoading = true
      this.errors = null

      await this.getServiceCatalogConfig()

      this.reset = true
      this.resetLoading = false
      window.clearInterval(this.refreshIntervalID)
    },
    async refreshPipeline ({ suppressModal = false } = {}) {
      const { pipelineCanonical } = this
      this.refreshLoading = true
      this.errors = null

      await this.GET_PIPELINE_VARIABLES({ pipelineCanonical })

      if (this.pipelineVariables && _.isEmpty(this.pipelineErrors)) {
        await this.getServiceCatalogConfig()

        if (!suppressModal) this.refresh = true
        window.clearInterval(this.refreshIntervalID)

        this.updateVariablesForRefresh()
      } else this.errors = this.pipelineErrors

      this.refreshLoading = false
    },
    mergeYaml (...yamlContents) {
      const parsedYaml = yamlContents.map((content) => yaml.load(content))
      const JSONoutput = _.mergeWith({}, ...parsedYaml, (objValue, srcValue) => {
        if (Array.isArray(objValue) && Array.isArray(srcValue)) {
          return [...objValue, ...srcValue]
        }
      })

      return yaml.dump(JSONoutput)
    },
    mergeVariables (usecaseKey) {
      const defaultVariables = _.cloneDeep(this.serviceConfig[usecaseKey].pipeline.variables.content)
      const definedVariables = _.cloneDeep(this.pipelineVariables.yaml_vars)

      return this.mergeYaml(defaultVariables, definedVariables)
    },
    updateVariablesForRefresh () {
      const { has_saved_yaml_vars } = this.pipelineVariables

      if (_.camelCase(has_saved_yaml_vars) === 'true') {
        this.serviceConfig = _.mapValues(this.serviceConfig, (usecase, key) => {
          return _.set(usecase, 'pipeline.variables', {
            content: this.mergeVariables(key),
          })
        })
      }
    },
    cancel () {
      this.isMenuOpen = false
      this.reset = false
      this.edit = false
      this.sync = false
      this.refresh = false
      this.yaml = null
      this.validCodeEditor = true
      this.selectedUsecaseKey = null
      this.errors = null
      this.setConfigurationDirty(false)
      this.refreshPipelineView()
    },
    editPipeline () {
      this.CLEAR_PROJ_ERRORS('pipelines')
      this.edit = true
      window.clearInterval(this.refreshIntervalID)
      this.getPipelineConfig()
    },
    async toggleSplitScreen () {
      this.isSplitScreen = !this.isSplitScreen
      if (this.isSplitScreen) {
        await this.$nextTick()
        this.updateYAML()
      }
    },
    async openDiffModal () {
      const config = this.getLocalPipelineConfig()
      const payload = {
        pipelineCanonical: this.pipelineCanonical,
        config,
      }
      this.saveLoading = true
      const success = await this.GET_PIPELINE_DIFF(payload)
      this.saveLoading = false
      if (!success) return

      this.showDiff = true
    },
    openSyncDiffModal () {
      this.sync = true
      this.showDiff = true
    },
    onDiffModalCancel () {
      this.sync = false
      this.showDiff = false
    },
    async onDiffConfirm () {
      await this.updatePipeline()
      this.refreshPipelineView()
      this.showDiff = false
      this.sync = false
    },
    setConfigurationDirty (isDirty = true) {
      this.isConfigurationDirty = isDirty
    },
  },
  i18n: {
    messages: {
      en: {
        actionEditStackFormsText: 'Edit the variables for this environment through StackForms interface',
        actionEditStackFormsTitle: 'Edit StackForms',
        actionRefreshText: 'Refresh pipeline with defined variables',
        actionRefreshTitle: 'Refresh pipeline',
        actionResetText: 'Remove any configuration done for this environment',
        actionResetTitle: 'Reset pipeline',
        actionSyncText: 'This pipeline diverges from its original stack. This may happen when the pipeline has been edited manually or when its stack or configuration has been updated.',
        actionSyncTitle: 'Pipeline out-of-sync',
        btnReset: 'Reset pipeline',
        comparing: 'comparing',
        createPipelineBtn: 'Create a pipeline',
        editPipeline: 'Edit pipeline',
        outOfSync: 'out-of-sync',
        running: 'running',
        reviewChanges: 'Review changes',
        selectPipelineToResetWith: 'Select the pipeline you want to reset with:',
        turnSplitScreenOff: 'Show only code',
        turnSplitScreenOn: 'Show code and diagram',
        views: 'Views',
        warningRefreshPipeline: 'This will refresh the pipeline with the variables defined during project creation.',
        warningResetPipeline: 'This will reset the pipeline according to your stack template and therefore erase all prior manual modifications.',
        yamlPipeline: 'Yaml pipeline',
      },
      es: {
        actionEditStackFormsText: 'Edita las variables para este entorno a través de la interfaz StackForms',
        actionEditStackFormsTitle: 'Editar StackForms',
        actionRefreshText: 'Actualizar la pipeline con variables definidas',
        actionRefreshTitle: 'Actualizar la pipeline',
        actionResetText: 'Eliminar cualquier configuración realizada para este entorno',
        actionResetTitle: 'Reiniciar la pipeline',
        actionSyncText: 'Esta tubería diverge de su pila original. Esto puede suceder cuando la canalización se ha editado manualmente o cuando se ha actualizado su pila o configuración.',
        actionSyncTitle: 'Pipeline fuera de sincronización',
        btnReset: 'Reiniciar la pipeline',
        comparing: 'comparando',
        createPipelineBtn: 'Crear un pipeline',
        editPipeline: 'Editar la pipeline',
        outOfSync: 'fuera-de-sincronización',
        running: 'funcionando',
        reviewChanges: 'Revisar cambios',
        selectPipelineToResetWith: 'Seleccione la pipeline con la que desea reiniciar:',
        turnSplitScreenOff: 'Mostrar solo código',
        turnSplitScreenOn: 'Mostrar código y diagrama ',
        views: 'Vistas',
        warningRefreshPipeline: 'Esto actualizará la pipeline con las variables definidas durante la creación del proyecto.',
        warningResetPipeline: 'Esto reiniciará la pipeline con el modelo definido en el stack y borrará toda modificación anterior.',
        yamlPipeline: 'Yaml pipeline',
      },
      fr: {
        actionEditStackFormsText: `Éditer les variables de cet environnement via l'interface StackForms`,
        actionEditStackFormsTitle: 'Éditer StackForms',
        actionRefreshText: 'Actualiser la pipeline avec les variables définies',
        actionRefreshTitle: 'Actualiser la pipeline',
        actionResetText: 'Supprimez toute configuration effectuée pour cet environnement',
        actionResetTitle: 'Réinitialiser la pipeline',
        actionSyncText: `Ce pipeline s'écarte de sa pile d'origine. Cela peut se produire lorsque le pipeline a été modifié manuellement ou lorsque sa pile ou sa configuration a été mise à jour.`,
        actionSyncTitle: 'Pipeline désynchronisés',
        btnReset: 'Réinitialiser la pipeline',
        comparing: 'comparant',
        createPipelineBtn: 'Créer une pipeline',
        editPipeline: 'Éditer la pipeline',
        outOfSync: 'désynchronisés',
        running: 'en fonctionnement',
        reviewChanges: 'Examiner les modifications',
        selectPipelineToResetWith: 'Sélectionnez la pipeline avec laquelle vous souhaitez réinitialiser :',
        turnSplitScreenOff: 'Afficher uniquement le code',
        turnSplitScreenOn: 'Afficher le code et le diagramme',
        views: 'Vues',
        warningRefreshPipeline: 'Cela actualisera la pipeline avec les variables définies lors de la création du projet.',
        warningResetPipeline: 'Ceci réinitialisera la pipeline avec le modèle défini dans la stack et effacera toutes modifications antérieures.',
        yamlPipeline: 'Yaml pipeline',
      },
    },
  },
}
</script>

<style lang="scss">  /* FIXME: scope!! */
$base00: #1a252f;
$base01: #273747;
$base02: #34495e;
$base03: #5d6d7e;
$base04: #bdc3c7;
$base05: #e6e7e8;
$base06: #f5f5f5;
$base07: get-color("grey", "light-2");
$base08: get-color("build", "failed");
$base09: get-color("build", "errored");
$base10: get-color("build", "running");
$base11: get-color("build", "succeeded");
$base12: #1abc9c;
$base13: get-color("build", "paused");
$base14: #9b59b6;
$base15: get-color("build", "aborted");
$base16: #3d4a58;
$base17: #e74c3c;
$white: get-color("white");
$pinned: #5c3bd1;

@keyframes x-large-pending-ripples-concourse {
  0% { box-shadow: 0 0 0 -20px $white, 0 0 0 0 $base10, 0 0 0 20px $white, 0 0 0 40px $base04; }
  50% { box-shadow: 0 0 0 0 $white, 0 0 0 20px $base04, 0 0 0 40px $white, 0 0 0 60px rgba($base04, 0.5); }
  100% { box-shadow: 0 0 0 20px $white, 0 0 0 40px $base04, 0 0 0 60px $white, 0 0 0 80px transparent; }
}

@keyframes large-pending-ripples-concourse {
  0% { box-shadow: 0 0 0 -15px $white, 0 0 0 0 $base04, 0 0 0 15px $white, 0 0 0 30px $base04; }
  50% { box-shadow: 0 0 0 0 $white, 0 0 0 15px $base04, 0 0 0 30px $white, 0 0 0 45px rgba($base04, 0.5); }
  100% { box-shadow: 0 0 0 15px $white, 0 0 0 30px $base04, 0 0 0 45px $white, 0 0 0 60px transparent; }
}

@keyframes medium-pending-ripples-concourse {
  0% { box-shadow: 0 0 0 -7px $white, 0 0 0 0 $base04, 0 0 0 7px $white, 0 0 0 14px $base04; }
  50% { box-shadow: 0 0 0 0 $white, 0 0 0 7px $base04, 0 0 0 14px $white, 0 0 0 21px rgba($base04, 0.5); }
  100% { box-shadow: 0 0 0 7px $white, 0 0 0 14px $base04, 0 0 0 21px $white, 0 0 0 28px transparent; }
}

@keyframes small-pending-ripples-concourse {
  0% { box-shadow: 0 0 0 -3px $white, 0 0 0 0 $base04, 0 0 0 3px $white, 0 0 0 6px $base04; }
  50% { box-shadow: 0 0 0 0 $white, 0 0 0 3px $base04, 0 0 0 6px $white, 0 0 0 9px rgba($base04, 0.5); }
  100% { box-shadow: 0 0 0 3px $white, 0 0 0 6px $base04, 0 0 0 9px $white, 0 0 0 12px transparent; }
}

@keyframes xlarge-running-ripples-concourse {
  0% { box-shadow: 0 0 0 -20px $white, 0 0 0 0 get-color("warning", "main"), 0 0 0 20px $white, 0 0 0 40px get-color("warning", "main"); }
  50% { box-shadow: 0 0 0 0 $white, 0 0 0 20px get-color("warning", "main"), 0 0 0 40px $white, 0 0 0 60px get-color("warning", "main", $alpha: 0.5); }
  100% { box-shadow: 0 0 0 20px $white, 0 0 0 40px get-color("warning", "main"), 0 0 0 60px $white, 0 0 0 80px transparent; }
}

@keyframes large-running-ripples-concourse {
  0% { box-shadow: 0 0 0 -15px $white, 0 0 0 0 get-color("warning", "main"), 0 0 0 15px $white, 0 0 0 30px get-color("warning", "main"); }
  50% { box-shadow: 0 0 0 0 $white, 0 0 0 15px get-color("warning", "main"), 0 0 0 30px $white, 0 0 0 45px get-color("warning", "main", $alpha: 0.5); }
  100% { box-shadow: 0 0 0 15px $white, 0 0 0 30px get-color("warning", "main"), 0 0 0 45px $white, 0 0 0 60px transparent; }
}

@keyframes medium-running-ripples-concourse {
  0% { box-shadow: 0 0 0 -7px $white, 0 0 0 0 get-color("warning", "main"), 0 0 0 7px $white, 0 0 0 14px get-color("warning", "main"); }
  50% { box-shadow: 0 0 0 0 $white, 0 0 0 7px get-color("warning", "main"), 0 0 0 14px $white, 0 0 0 21px get-color("warning", "main", $alpha: 0.5); }
  100% { box-shadow: 0 0 0 7px $white, 0 0 0 14px get-color("warning", "main"), 0 0 0 21px $white, 0 0 0 28px transparent; }
}

@keyframes small-running-ripples-concourse {
  0% { box-shadow: 0 0 0 -3px $white, 0 0 0 0 get-color("warning", "main"), 0 0 0 3px $white, 0 0 0 6px get-color("warning", "main"); }
  50% { box-shadow: 0 0 0 0 $white, 0 0 0 3px get-color("warning", "main"), 0 0 0 6px $white, 0 0 0 9px get-color("warning", "main", $alpha: 0.5); }
  100% { box-shadow: 0 0 0 3px $white, 0 0 0 6px get-color("warning", "main"), 0 0 0 9px $white, 0 0 0 12px transparent; }
}

.row {
  position: relative;
}

.menu-actions {
  &__btn.v-btn.v-btn:not(.cy-btn--icon) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &__dropdown-toggle {
    height: 100%;
    padding: 0 0.3rem;
    border: 1px solid get-color("primary", "main");
    border-left: 0 !important;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;

    &:disabled {
      border-color: rgba(0 0 0 / 26%);
    }

    &:hover {
      background: get-color("primary", "light-4");
    }

    &::after {
      transform: scale(1) !important;
      border-radius: 0 !important;
    }
  }

  &__list {
    max-width: 300px;

    &-item {
      min-height: auto !important;
      padding: 8px 16px;

      .v-list-item {
        height: auto !important;

        &__action,
        &__content {
          margin: 0 auto;
          padding: 0 !important;
        }

        &__action {
          align-self: flex-start;
        }

        &__subtitle {
          overflow: normal;
          text-overflow: clip;
          white-space: normal;
        }
      }

      .v-icon {
        width: 1em;
        height: 1em;
      }

      p {
        margin-bottom: 0;
      }

      &:hover {
        & > .v-list-item__content,
        & > .v-list-item__content .v-list-item__subtitle,
        & .v-icon {
          color: get-color("secondary");
        }
      }

      .v-list__tile--link:hover {
        background: none !important;
      }
    }

    &.v-list--dense .v-list-item .v-list-item__content {
      align-self: flex-start;
      margin-top: 4px;
      padding-top: 0;
      padding-bottom: 7px;
    }

    .list-item {
      &__title {
        font-size: 14px;
        line-height: 21px;
      }

      &__text {
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
}

.v-menu__content {
  margin-top: 0.5em;
  border: 1px solid get-color("grey");
}

.legend {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  &__item {
    display: flex;
    padding-right: 1em;
  }

  dd {
    margin-left: $spacer * 2;
    font-size: map.get($font-sizes, "base");
  }

  dt {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;

    .v-icon {
      color: inherit;
    }

    &.pending {
      color: get-color("grey", "dark-3", 0.3);
      box-shadow: 0 0 0 2px $white, 0 0 0 5px get-color("grey", "dark-3", 0.3);
    }

    &.failed {
      color: $base08;
      box-shadow: 0 0 0 2px $white, 0 0 0 5px get-color("error", $alpha: 0.3);
    }

    &.manual-trigger {
      outline: 2px dashed $base03;
    }

    &.succeeded {
      color: $base11;
    }

    &.errored {
      color: $base09;
    }

    &.aborted {
      color: $base15;
    }

    &.paused {
      color: $base13;
    }

    &.pinned {
      color: $white;
      background: $pinned;

      .v-icon {
        font-size: 16px;
      }
    }

    &.running {
      animation: small-running-ripples-concourse 1s linear infinite;
    }

    &.dotted-line,
    &.solid-line {
      &:after {
        content: "";
        display: block;
        width: 100%;
        margin: auto;
        border-width: 2px 0 0 0;
      }
    }

    &.dotted-line {
      &:after {
        border-top-color: get-color("primary");
        border-style: dotted;
      }
    }

    &.solid-line {
      &:after {
        border-top-color: get-color("primary");
        border-style: solid;
      }
    }
  }
}

#pipeline {
  display: flex;
  position: relative;
  flex: 1 1 auto;
  flex-direction: column;
  width: 100%;
  height: auto;
  min-height: 0;

  svg.pipeline-graph {
    z-index: 1;
    flex: 1 1 0;
    width: 100%;
    height: 100%;
    min-height: 0;

    h1 {
      width: 100%;
      margin: 5px;
    }

    h1.resource {
      width: 100%;
      font-size: 1.5em;
      font-weight: normal;
    }

    .node rect {
      fill: $base00;
      shape-rendering: crispEdges;
    }

    .active.node rect { filter: url("#embiggen"); }

    .node.job {
      &.pending rect { fill: $base04; }
      &.succeeded rect { fill: $base11; }
      &.failed rect { fill: $base08; }
      &.errored rect { fill: $base09; }
      &.aborted rect { fill: $base15; }
      &.paused rect { fill: $base13; }
      &.started { background: $base10; }
    }

    .node.resource {
      &.failed rect { stroke: $base08; }
      &.errored rect { stroke: $base09; }
      &.aborted rect { stroke: $base15; }
      &.pinned rect { fill: $pinned; }
    }

    .node.job.no-builds {
      rect {
        background: $base03;
        fill: $base03;
      }
    }

    .node.input.failing { rect { fill: $base09; } }

    .pipeline-grid .node a { z-index: 1; }
    .node.job h1 a { font-weight: bold; }

    .node.constrained-input.pinned {
      rect {
        opacity: 0.8;
        fill: $pinned;
      }

      image {
        opacity: 0.7;
      }
    }

    .edge path {
      stroke-width: 2px;
      fill: none;
    }

    .node text { fill: get-color("white"); }
    .node.job text { font-weight: bold; }
    .node.constrained-input { text { fill: $base04; } }
    .active path { stroke-width: 4px; }
    .active.node text { font-size: 1.06em; }

    .edge.pending { stroke: $base04; }
    .edge.succeeded { stroke: $base11; }
    .edge.failed { stroke: $base08; }
    .edge.errored { stroke: $base09; }
    .edge.aborted { stroke: $base15; }
    .edge.paused { stroke: $base13; }
    .edge.trigger-false { stroke-dasharray: 5, 5; }
    .edge { stroke: $base03; }

    .job-animation-node .animation,
    .pipeline-grid .node .running,
    .pipeline-grid .node .started,
    .pipeline-grid .node .pending,
    .pipeline-grid .node .manual-trigger,
    .pipeline-grid .node .failed {
      border-radius: 1px;
    }

    .fail-triangle {
      stroke-linecap: round;
      stroke: $base01;
      fill: $base08;
      stroke-linejoin: round;
    }

    .fail-triangle-outline {
      opacity: 0.7;
      fill: $base08;
    }

    .job-animation-node.pending {
      .animation-xlarge { box-shadow: 0 0 0 24px $white, 0 0 0 60px rgba($base04, 0.5); }
      .animation-large { box-shadow: 0 0 0 18px $white, 0 0 0 45px rgba($base04, 0.5); }
      .animation-medium { box-shadow: 0 0 0 8px $white, 0 0 0 20px rgba($base04, 0.5); }
      .animation-small { box-shadow: 0 0 0 4px $white, 0 0 0 10px rgba($base04, 0.5); }
    }

    .job-animation-node.started {
      .animation-xlarge { animation: xlarge-running-ripples-concourse 1s linear infinite; }
      .animation-large { animation: large-running-ripples-concourse 1s linear infinite; }
      .animation-medium { animation: medium-running-ripples-concourse 1s linear infinite; }
      .animation-small { animation: small-running-ripples-concourse 1s linear infinite; }
    }

    .job-animation-node.manual-trigger {
      .animation-xlarge { outline: 2px dashed $base03; }
      .animation-large { outline: 2px dashed $base03; }
      .animation-medium { outline: 2px dashed $base03; }
      .animation-small { outline: 2px dashed $base03; }
    }

    .job-animation-node.failed {
      .animation-xlarge { box-shadow: 0 0 0 24px $white, 0 0 0 60px get-color("build", "failed", 0.3); }
      .animation-large { box-shadow: 0 0 0 18px $white, 0 0 0 45px get-color("build", "failed", 0.3); }
      .animation-medium { box-shadow: 0 0 0 8px $white, 0 0 0 20px get-color("build", "failed", 0.3); }
      .animation-small { box-shadow: 0 0 0 4px $white, 0 0 0 10px get-color("build", "failed", 0.3); }
    }

    .pipeline-grid .node .failed {
      box-shadow: 0 0 0 8px $white, 0 0 0 20px get-color("build", "failed", 0.3);
    }

    .pipeline-grid .node a.started {
      z-index: 0;
      animation: medium-running-ripples-concourse 1s linear infinite;
    }

    .pipeline-grid .node .manual-trigger {
      z-index: 0;
      animation: medium-manual-trigger-ripples-concourse 1s linear infinite;
    }

    .pipeline-grid .node .pending {
      z-index: 0;
      box-shadow: 0 0 0 8px $white, 0 0 0 20px rgba($base04, 0.5);
    }
  }
}

.inactive-link {
  cursor: default;
  pointer-events: none;
}
</style>

<style lang="scss" scoped>
.pipeline-container {
  min-height: 0;
}

.blur {
  filter: blur(3px);
}

.loading-spinner {
  position: absolute;
  z-index: 9;
  top: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  color: black;
  text-align: center;
}

.warning-box {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  margin: 4px auto;
  padding: 16px;
  border: 1px solid !important;
  border-radius: 5px;
  border-color: get-color("warning");
  color: get-color("warning");
  font-size: 14px;

  .v-icon {
    padding-right: 10px;
    color: get-color("warning");
  }
}

.sync-check-btn {
  height: 20px;

  &--comparing {
    .v-progress-circular {
      position: relative;
      top: -2px;
      bottom: 0;
    }
  }

  &--out-of-sync {
    ::v-deep .v-icon {
      color: get-color("warning", "dark-2");
    }

    &:active .v-icon,
    &:hover .v-icon,
    &:focus .v-icon {
      color: get-color("warning", "light-2");
    }
  }
}

.btn-pipeline ::v-deep label,
.btn-paused ::v-deep label {
  display: block;
  color: get-color("black", $alpha: 0.5);
  font-size: 12px;
}

// pause button has different accent color
.status {
  &__buttons {
    ::v-deep .cy-btn.v-btn.v-btn--active[value="pause"] {
      border-color: get-color("paused", "main") !important;
      background-color: get-color("paused", "light-2") !important;
      color: get-color("paused", "main") !important;
    }

    ::v-deep .cy-btn.v-btn[value="pause"] {
      &:hover {
        border-color: get-color("paused", "main") !important;
        background: get-color("paused", "light-2") !important;
        color: get-color("paused", "main") !important;
      }
    }
  }
}

.button-actions {
  display: flex;
  position: relative;
}

.container-reset-editor {
  min-height: 0;

  ::v-deep .fill-height {
    flex-grow: 1;
    height: auto;
    min-height: 0;
  }
}

#icon-store {
  display: none;
}

</style>
