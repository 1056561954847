<template>
  <div
    v-if="block.content.length"
    class="diff-split">
    <div
      v-if="isCollapsed"
      class="line line--fold">
      <CyTooltip right>
        <template #activator="{ on }">
          <button
            class="line--fold__button"
            v-on="on"
            @click="expand()">
            <v-icon>unfold_more</v-icon>
          </button>
        </template>
        {{ $t('expand') }}
      </CyTooltip>
    </div>

    <div
      v-else
      v-for="(line, lineIndex) in block.content"
      :key="`line-${lineIndex}`"
      :class="['line', getLineClass(line.type), { 'line--before': !isOdd(lineIndex) }, { 'line--after': isOdd(lineIndex) }]"
      :data-line-number="line.lineNumber">
      <span
        v-if="line.content"
        class="line__content">
        {{ line.content }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CyCodeDiffBlock',
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  data: ({ block }) => ({
    isCollapsed: block.isJunk,
  }),
  methods: {
    getLineClass (type) {
      return {
        '+': 'line--add',
        '-': 'line--remove',
        spacer: 'line--spacer',
      }[type] || ''
    },
    isOdd (number) {
      return number % 2 !== 0
    },
    expand () {
      this.isCollapsed = false
    },
  },
  i18n: {
    messages: {
      en: {
        expand: 'Expand',
      },
      es: {
        expand: 'Expandir',
      },
      fr: {
        expand: 'Étendre',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
$line-height: 20px;

.diff-split {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  background-color: get-color("background");
  font-family: $font-family-code;
  font-size: 1rem;
  line-height: $line-height;
  text-align: left;
  white-space: nowrap;
  counter-reset: before after;
}

.line {
  display: flex;
  flex-basis: 50%;
  min-width: 0;

  &[data-line-number] {
    &::before {
      content: attr(data-line-number);
      display: inline-block;
      flex-shrink: 0;
      width: 3ch;
      margin: 0 3ch 0 1ch;
      color: get-color("primary", $alpha: 0.5);
      text-align: right;
    }

    &:hover::before {
      color: get-color("primary");
    }
  }

  &--after {
    border-left: 1px solid get-color("white");
  }

  &--add,
  &--remove {
    > .line__content::before {
      margin: 0 1ch 0 -2ch;
    }
  }

  &--add {
    background-color: get-color("success", $alpha: 0.2);
    color: get-color("success", "dark-2");

    > .line__content::before {
      content: "+";
    }
  }

  &--remove {
    background-color: get-color("error", $alpha: 0.2);
    color: get-color("error", "dark-1");

    > .line__content::before {
      content: "-";
    }
  }

  &--spacer {
    min-height: $line-height;
    background-color: get-color("grey", "light-3");
  }

  &--fold {
    flex-basis: 100%;
    height: $line-height;
    background-color: get-color("grey", "light-3");

    &__button {
      width: 5ch;
      transition: all 0.2s ease;
      background-color: get-color("grey", "light-1");
      line-height: 1em;

      ::v-deep .v-icon {
        color: get-color("grey", "dark-2");
        font-size: 15px;
      }

      &:hover {
        background-color: get-color("grey", "dark-2");

        ::v-deep .v-icon {
          color: get-color("grey", "light-1");
        }
      }
    }
  }

  &__content {
    flex-shrink: 1;
    min-width: 0;
    word-break: break-word;
    white-space: pre-wrap;
  }
}
</style>
