import { render, staticRenderFns } from "./code-diff-block.vue?vue&type=template&id=78cb2810&scoped=true&"
import script from "./code-diff-block.vue?vue&type=script&lang=js&"
export * from "./code-diff-block.vue?vue&type=script&lang=js&"
import style0 from "./code-diff-block.vue?vue&type=style&index=0&id=78cb2810&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78cb2810",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VIcon})
