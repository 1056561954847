<template>
  <CyViewsPipeline
    v-if="pipeline"
    v-has-rights-to="['GetPipeline', pipeline.name]"
    :pipeline-canonical="pipeline.name"
    :project-canonical="projectCanonical"
    @refresh-pipelines-selector="GET_PROJECT_PIPELINES"/>
</template>

<script>
import { constructBreadcrumb } from '@/utils/helpers'
import { mapState, mapActions, mapGetters } from 'vuex'
import CyViewsPipeline from '@/components/views/pipeline'

export default {
  name: 'CyPagePipelines',
  components: {
    CyViewsPipeline,
  },
  breadcrumb () {
    const { projectCanonical, projectName, envCanonical } = this
    return constructBreadcrumb(this.$options.name, envCanonical, [
      {
        label: this.$t('routes.projectEnvironments'),
        name: 'project',
        params: { projectCanonical },
      },
      {
        label: projectName,
        name: 'project',
        params: { projectCanonical, envCanonical },
      },
      {
        label: this.$t('routes.projectsSection'),
        name: 'projectsSection',
      },
    ])
  },
  header () {
    return {
      title: `${this.envCanonical} start-stop ${_.toLower(this.$t('untranslated.pipeline'))}`,
      avatar: {
        type: 'environmentIcon',
        item: _.find(this.envs, ['canonical', this.envCanonical]),
      },
    }
  },
  props: {
    projectCanonical: {
      type: String,
      default: '',
    },
    pipelineCanonical: {
      type: String,
      default: '',
    },
    envCanonical: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    pipeline: null,
  }),
  computed: {
    ...mapState('organization/project', {
      pipelines: (state) => state.pipelines,
    }),
    ...mapGetters('organization/project', [
      'envs',
    ]),
  },
  watch: {
    pipelineCanonical () {
      this.setPipelineFromRoute()
    },
  },
  async mounted () {
    await this.GET_PROJECT_PIPELINES()
    this.setPipelineFromRoute()
  },
  methods: {
    ...mapActions('organization/project', [
      'GET_PROJECT_PIPELINES',
    ]),
    setPipelineFromRoute () {
      if (_.isEmpty(this.pipelines)) return
      this.pipeline = _.find(this.pipelines, ['name', this.pipelineCanonical]) || this.pipelines[0]
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:routes.pipeline',
        choosePipelineHint: 'Please choose a pipeline',
        createPipelineBtn: 'Create a pipeline',
        editPipelineBtn: 'Edit a pipeline',
      },
      es: {
        title: '@:routes.pipeline',
        choosePipelineHint: 'Por favor, elija un pipeline',
        createPipelineBtn: 'Crear un pipeline',
        editPipelineBtn: 'Editar un pipeline',
      },
      fr: {
        title: '@:routes.pipeline',
        choosePipelineHint: 'Veuillez selectionner une pipeline',
        createPipelineBtn: 'Créer une pipeline',
        editPipelineBtn: 'Editer une pipeline',
      },
    },
  },
}
</script>
